@import '../scss/variables';

.box {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    background-color: $WHITE;
    box-shadow: $SHADOW;
    border-radius: 8px;
    padding: 24px;
    margin-top: 48px;

    @media screen and (max-width: 440px) {
      padding: 16px;
    }
  }

  &__header {
    color: $WHITE;
    background-color: $GREEN_DARK;
    border-radius: 8px;
    margin-top: -48px;
    margin-bottom: 16px;
    padding: 24px;

    @media screen and (max-width: 440px) {
      padding: 16px;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 42px;
    margin-bottom: 16px;

    @media screen and (max-width: 834px) {
      font-size: 1.6rem;
    }
  }

  &__sub-title {
    font-size: 1.3rem;
    font-weight: 400;

    @media screen and (max-width: 834px) {
      font-size: 1.1rem;
    }
  }

  &__text {
    font-size: 16px;
  }

  &__cta {
    align-self: center;
  }
}
