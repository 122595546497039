@import 'assets/scss/variables';
@import 'assets/scss/reset';
@import 'assets/scss/buttons';
@import 'assets/scss/form';
@import 'assets/scss/box';

* {
  box-sizing: border-box;
}

html,
body {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  line-height: 28px;
  color: rgba(45, 45, 45, 0.96);
  height: fit-content;
  margin: 0;
  padding: 0;
}

body {
  background-color: $NEUTRAL_11;
}

h1 {
  font-family: "Playfair Display", serif;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 64px;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 48px;
  }
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 42px;

  @media screen and (max-width: 768px) {
    font-size: 1.66rem;
    line-height: 32px;
  }
}

h3 {
  @include HEADING_3;

  @media screen and (max-width: 768px) {
    font-size: 1.11rem;
  }
}

h4 {
  font-size: 1.15rem;
  font-weight: 700;
}

h5 {
  font-size: 1rem;
  font-weight: 700;
}

h6 {
  font-size: 0.9rem;
  font-weight: 700;
}

p,
label {
  font-size: 1rem;
  line-height: 28px;
}

strong,
b {
  font-weight: 700;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

blockquote {
  font-style: italic;
  border-left: 4px solid $NEUTRAL_10;
  padding-left: 16px;
}

.wysiwyg {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  sup {
    font-size: 0.8em;
    vertical-align: super;
  }

  sub {
    font-size: 0.8em;
    vertical-align: sub;
  }

  ul {
    list-style: disc;
    padding-left: 16px;
    margin-bottom: 16px;

    ul {
      list-style: circle;

      ul {
        list-style: square;

        ul {
          list-style: disc;
        }
      }
    }
  }

  ol {
    list-style: decimal;
    padding-left: 16px;
    margin-bottom: 16px;

    ol {
      list-style: upper-alpha;

      ol {
        list-style: lower-roman;

        ol {
          list-style: lower-alpha;
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
    margin: 24px 0;
  }
}

.container {
  width: 100%;
  max-width: 1196px;
  margin: 0 auto;
  padding: 0 24px 0 24px;

  @media screen and (orientation: landscape) {
    padding: 0 env(safe-area-inset-right, 24px) 0 env(safe-area-inset-left, 24px);
  }

  @media screen and (max-width: 1196px) {
    padding: 0 16px 0 16px;
  }

  @media screen and (max-width: 1196px) and (orientation: landscape) {
    padding: 0 env(safe-area-inset-right, 16px) 0 env(safe-area-inset-left, 16px);
  }

  &--sm {
    max-width: 768px;
  }

  &--md {
    max-width: 1024px;
  }
}

.hr {
  &--vertical {
    width: 1px;
    height: 24px;
    border: none;
    background-color: $LIGHT_GREY;
  }

  &--horizontal {
    width: 100%;
    height: 1px;
    border: none;
    background-color: $LIGHT_GREY;
  }
}

.hide-768 {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.list {
  list-style: unset;
  padding: unset;
  margin: unset;

  &--bullet {
    list-style: disc;
    padding-left: 24px;
  }
}
