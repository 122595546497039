@import '../../assets/scss/variables';

.btn {
  font-size: 1rem;
  color: $BLACK;
  align-items: center;
  text-decoration: none;
  height: fit-content;
  line-height: 1;
  border-radius: 8px;
  padding: 8px 16px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 0.8rem;
    padding: 8px 8px;
  }

  &--primary {
    background-color: $YELLOW;

    &:hover {
      opacity: 0.8;
    }
  }

  &--link {
    color: $BLACK;
    background-color: transparent;
    border: 1px solid $LIGHT_GREY;
    padding: 8px;

    &:hover {
      background-color: $LIGHT_GREY;
    }
  }

  &--hamburger {
    position: relative;
    width: 36px;
    height: 36px;
    background: $GREEN_DARK;
    border-radius: 4px;
    text-indent: -9999px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 36px;
      height: 36px;
      top: 0;
      left: 0;
      background: url('../../assets/images/navigation-menu.svg') no-repeat center center;
      background-size: 24px 15px;
    }
  }
}
