.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__legend {
    @extend h3;
    margin-bottom: 24px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--row {
      flex-direction: row;

      @media screen and (max-width: 630px) {
        flex-direction: column;
      }
    }
  }

  &__group-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 8px;
    align-items: center;

    > input {
      flex: 1;
    }
  }

  &__errors {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #FF0000;
    margin-top: -8px;
  }

  &__error {
    font-size: 0.66rem;
    color: red;
  }

  &__api-error {
    color: #930800;
    background: rgba(255, 59, 48, .25);
    border-radius: 16px;
    padding: 16px;
  }

  &__success {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 36px;
  }
}

input,
textarea {
  border: 1px solid #C1C1C1;
  background: rgba(255, 255, 255, 0.50);
  padding: 11px 20px;
}

input {
  border-radius: 100px;
}

textarea {
  border-radius: 12px;
  resize: vertical;
}
