$WHITE: #FFFFFF;
$LIGHT_GREY: #E0E5EB;
$BLACK: #0A2620;
$YELLOW: #E4A239;
$GREEN_LIGHT: #D7E9D0;
$GREEN_DARK: #3A665C;
$SHADOW: 0 8px 16px 0 rgba(0, 0, 0, .08);

$NEUTRAL_1: #252934;
$NEUTRAL_2: #373A41;
$NEUTRAL_3: #42464E;
$NEUTRAL_4: #4F545E;
$NEUTRAL_5: #5F6571;
$NEUTRAL_6: #727A88;
$NEUTRAL_7: #89909C;
$NEUTRAL_8: #A4A9B3;
$NEUTRAL_9: #C4C7CE;
$NEUTRAL_10: #E0E5EB;
$NEUTRAL_11: #F2F5F9;
$NEUTRAL_12: #F9FBFD;

@mixin HEADING_3 {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 34px;
}

$BODY_1: 1rem;

$SMALL_1: 0.7rem;

@mixin hero {
  padding-top: 307px;

  @media screen and (max-width: 1024px) {
    padding-top: 105px;
  }
}

@mixin greenHero {
  width: 100%;
  background-color: $GREEN_LIGHT;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
